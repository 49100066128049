<template>
  <h1>Players</h1>
</template>

<script>
export default {
  name: 'Players'
}
</script>

<style scoped>
</style>
